<script
    lang="ts"
    setup
>
    type Props = {
        opposite?: boolean
    }

    const props = defineProps<Props>()

    const { maxTablet } = useWindowSize()
</script>

<template>
    <ClientOnly>
        <slot
            v-if="props.opposite ? !maxTablet : maxTablet"
            key="default"
            name="default"
        />

        <slot
            v-else
            key="fallback"
            name="fallback"
        />
    </ClientOnly>
</template>
